@import "../../../src/theme/colors.scss";

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $white-color;
  border-bottom: solid 1px $nav-gray-color;
  flex-direction: row-reverse;
  height: 57px;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.navbarWithNoBorder {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $white-color;
  flex-direction: row-reverse;
  height: 57px;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.burgerIcon {
  &:hover {
    cursor: pointer;
  }
}

.leftArrowIcon {
  [dir='rtl'] & {
    rotate: 180deg;
  }

  &:hover {
    cursor: pointer;
  }
}

.logoContainer {
  display: flex;
  align-items: center;
}

.brokerageLogo {
  width:80px ;
  border-right: solid 1px $nav-gray-color;
  padding-right: 5px;
}

.investLogo {
  width: 100px;
}

