#privacy {
  font-family: catamaranRegular;

  .banner {
    background-color: #21376b;
    padding: 40px 0;
    margin-bottom: 20px;

    .container {
      padding-top: 0;
      padding-bottom: 0;

      .title {
        p {
          font-size: 54px;
          font-weight: 700;
          color: #fff;
          font-family: cormorantRegular;
          text-align: left;
        }
      }
    }
  }

  .sections-body {
    .section {
      margin-bottom: 25px;

      .section-header {
        .section-title {
          color: #191c1f;
          margin: 0;
          font-size: 24px;
          line-height: 1.167;
          margin-bottom: 14px;
          font-weight: 600;
          font-family: cormorantRegular;
        }
      }

      .section-content {
        .section-text {
          margin: 0;
          font-size: 14px;
          line-height: 1.5;
          font-weight: normal;
          text-align: left;

          .link-item {
            text-decoration: none;
            color: #191c1f;
          }
        }
      }

      .section-title-big {
        margin: 0;
        line-height: 1.5;
        font-size: 28px;
        margin-bottom: 48px;
        font-weight: 700;
        color: #191c1f;
        font-family: cormorantRegular;
        text-align: left;
      }
    }
  }
}

#privacy {
  &.rtl {
    text-align: right;
    font-family: readexproregular;
    direction: rtl;

    .banner {
      .container {
        .title {
          p {
            font-family: readexproregular;
          }
        }
      }
    }

    .section-header {
      direction: rtl;

      .section-title {
        font-family: readexproregular !important;
      }
    }
  }
}

@media only screen and (max-width: 993px) {
  #privacy {
    .container {
      .title {
        p {
          font-size: 30px !important;
        }
      }
    }
  }
}