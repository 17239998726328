.landing-flex {
  padding: 23px;
  &.mobile {
    display: block !important;
    .device-section {
      .logo {
        width: 100px;
        img {
          margin-bottom: 30px;
          width: 100%;
        }
      }
      .devices-image {
        margin-bottom: 30px !important;
        width: 80% !important;
        margin: 0 auto;
      }
    }
    .landing-info {
      .title {
        margin-bottom: 13px;
        p {
          font-size: 30px !important;
          line-height: 40px;
          margin-bottom: 12px !important;
        }
      }
      .list {
        ol {
          li {
            font-size: 13px !important;
            &::before {
              content: counter(item);
              font-size: 20px !important;
            }
          }
        }
      }
    }
  }
}

.landing-flex {
  &.mobile {
    &.rtl {
      .landing-info {
        .title {
          p {
            margin-bottom: 20px !important;
          }
        }
      }
    }
  }
}
