* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
  background-color: #fff;
  color: #191c1f;
}

.container {
  width: 100%;
  max-width: 960px;
  margin: 0 auto;
  padding: 20px;
  box-sizing: border-box;
}

.boldText {
  font-family: catamaranBold;
}

@media screen and (min-width: 768px) {
  .container {
    padding: 30px;
  }
}

@media screen and (min-width: 1024px) {
  .container {
    max-width: 1200px;
  }
}

#landing {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 100vh;
  padding: 120px 0 0 0;
  display: flex;
  flex-direction: column;

  .landing-flex {
    display: flex;
    justify-content: space-between;

    .landing-info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .logo {

        img {
          margin-bottom: 34px;
          width: 200px;
        }
      }

      .title {
        p {
          font-size: 50px;
          color: #191c1f;
          font-family: catamaranRegular;
          margin-bottom: 20px;
          font-weight: bold;
        }
      }

      .list {

        ol {
          list-style: none;
          counter-reset: item;
          display: flex;
          flex-direction: column;
          align-items: flex-start;

          li {
            color: #191c1f;
            font-size: 18px;
            font-family: catamaranRegular;
            counter-increment: item;
            margin-bottom: 5px;

            &::before {
              content: counter(item);
              color: #21376b;
              font-family: catamaranRegular;
              font-size: 30px;
              margin-right: 11px;
            }
          }
        }
      }
    }

    .device-section {
      .devices-image {
        width: 500px;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}

#landing {
  .rtl {
    flex-direction: row-reverse;

    .landing-info {
      .logo {
        img {
          margin-left: auto;
          display: block;
        }
      }

      .title {
        p {
          text-align: right;
          font-family: readexproregular !important;
          margin-bottom: 35px;
        }
      }

      .list {
        ol {
          text-align: right;

          li {
            font-family: readexproregular !important;

            &::before {
              content: "\06f1";
              unicode-bidi: bidi-override;
              direction: rtl;
              margin-left: 11px;
              margin-right: 0;
            }

            &:nth-child(2):before {
              content: "\06f2";
            }

            &:nth-child(3):before {
              content: "\06f3";
            }

            &:nth-child(4):before {
              content: "٤";
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 992px) {
  #landing {
    padding-top: 40px;
    background-position: right;
  }
}