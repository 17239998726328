@import "../../theme/fonts.scss";
@import "../../theme/spacing.scss";

.container {
  display: flex;
  align-items: center;
  padding: var(--medium2-spacing);
  border-radius: var(--medium-spacing);
  position: fixed;
  justify-content: center;
  top: 44px;
  z-index: 999;
  left: 50%;
  transform: translate(-50%);
  box-sizing: border-box;
  max-width: 100%;

}

.alertMessage {
  font-family: $primary-font;
  font-weight: 600;
  flex: 1;
  font-size: 12px;
  text-align: start;
  margin: 0 var(--medium-spacing);
  word-wrap: break-word;
  box-sizing: border-box;
}

.closeIcon {
  cursor: pointer;
}

.mobileCloseIcon {
  display: none;
}

@media screen and (max-width: 700px) {
  .container {
    top: 23px;
    min-width: 250px;
    width: calc(100% - 30px);
  }
}

@media screen and (max-width: 400px) {
  .container {
    [dir='rtl'] & {
      flex-direction: row-reverse;
    }

    padding: var(--medium-spacing);
    justify-content: space-between;
    align-items: center;
  }

  .alertMessage {
    margin: var(--small-spacing) 0;
    text-align: center;
    max-width: 90%;
  }

  .mobileCloseIcon {
    display: block;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }


}