#footer {
  margin-top: auto;
  padding: 20px;
  background-color: #ffffff;
  .footer-flex-row {
    padding: 0;
    display: flex;
    justify-content: space-between;
    .footer-links {
      display: flex;
      color: #ccbda8;
      font-family: catamaranRegular;
      .link {
        font-size: 16px;
        border-bottom: 1px solid #191c1f;
        &:nth-child(2) {
          margin-left: 22px;
        }
        .link-item {
          text-decoration: none;
          color: #191c1f;
        }
      }
    }
    .copyright {
      p {
        color: #191c1f;
        font-family: catamaranRegular;
        font-size: 12px;
      }
    }
  }
}

#header {
  height: 68px;
  .container {
    padding: 0 30px;
    .flex {
      display: flex;
      -webkit-box-align: center;
      align-items: center;
      height: 68px;
      .logo {
        img {
          width: 100px;
        }
      }
    }
  }
}

#header {
  &.rtl {
    direction: rtl;
  }
}

#footer {
  &.rtl {
    direction: rtl;
    .footer-flex-row {
      .footer-links {
        font-family: readexproregular;
        .link {
          &:first-child {
            margin-left: 22px;
          }
        }
      }
      .copyright {
        p {
          font-family: readexproregular;
        }
      }
    }
  }
}

@media only screen and (min-width: 1200px) {
  #footer {
    width: 1200px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 12px;
    box-shadow: 0 0 0 1px rgba(229, 229, 229, 1);
    margin-bottom: 43px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  #footer {
    margin-left: 23px;
    margin-right: 23px;
    border-radius: 12px;
    box-shadow: 0 0 0 1px rgba(229, 229, 229, 1);
    margin-bottom: 43px;
  }
}

@media only screen and (max-width: 992px) {
  .footer-flex-row {
    flex-direction: column;
    .footer-links {
      margin-bottom: 20px;
      .link {
        font-size: 12px !important;
      }
    }
    .copyright {
      p {
        font-size: 10px !important;
      }
    }
  }
}
