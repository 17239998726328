#unblock {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
  .body-info {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 100%;
    .icon {
      width: 100%;
      margin-bottom: 30px;
      img {
        width: 84px;
      }
    }
    .title {
      font-size: 50px;
      margin-bottom: 10px;
      color: #fff;
      font-family: cormorantRegular;
    }
    .description {
      font-size: 20px;
      color: #fff;
      font-family: catamaranRegular;
      line-height: 30px;
    }
  }
}

#unblock {
  &.rtl {
    .body-info {
      .title {
        font-family: readexproregular !important;
      }
      .description {
        font-family: readexproregular !important;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .body-info {
    .title {
      font-size: 40px !important;
    }
    .description {
      font-size: 18px !important;
    }
  }
}
